
import {Page, TextContainer, Text} from "@shopify/polaris";

export default function notFound() {
  // window.location.href='/'
  const style_bg = {
    width:'100%', 
    height:'60vh',
    background: 'url(https://cdn.shopify.com/shopifycloud/partners/assets/admin/empty-states-fresh/partners-generic-df54927807fc27d4c5cab379ebfed287002aefd3a468b6f6fd1d6ee86a70086d.svg)',
    backgroundRepeat: 'no-repeat'
  }
  return (
    <Page fullWidth={false}>
      <div style={style_bg}>
        <div style={{position: 'absolute', top: '10%',maxWidth: '50%'}}>
          <TextContainer>
            <Text variant="medium">The page you’re looking for could not be found</Text>
            <Text variant="small">
              <Text color="subdued">Please make sure the web address is correct.</Text>
            </Text>
          </TextContainer>
        </div>
      </div>
    </Page>
  )
}