import React, { useCallback, useState } from "react";
import cookie from 'react-cookies'
import {
  Navigation,
  TopBar,
  Frame,
  Toast,
  Modal,
  Stack,
  Text,
  InlineCode,
  Badge
} from "@shopify/polaris";

import { customFetch } from "./tools/request";

import {
  ProfileMinor, QuestionMarkInverseMinor, LogOutMinor,SettingsMinor, NoteMajor, CircleInformationMajor, InfoMinor
} from "@shopify/polaris-icons"

export default function LayoutComp(props){

  // console.log('LayoutComp props', props)
  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);
  const [userMenuActive, setUserMenuActive] = useState(false);

  const [activeModalInfo, setActiveModalInfo] = useState(false); 
  const toggleActiveModalInfo = useCallback(() => setActiveModalInfo((active) => !active), []);
  const [modalInfoData, setModalInfoData] = useState({
    title:'Default',
    section:{},
    loading:false
  })

  const [active, setActive] = useState(false);
  const [toastMessage, setToastMessage] = useState('OK')
  const [toastError, setToastError] = useState(false)
  const toggleActive = useCallback(() => setActive((active) => !active), []);

  const toastMarkup = active ? (
    <Toast content={toastMessage} error={toastError} onDismiss={toggleActive} />
  ) : null;  

  window.AppGlobal.Toast = {
    show:function({content='', error=false }){
      setToastMessage(content);
      setToastError(error);
      toggleActive();
    }
  }

  window.AppGlobal.ModalInfo = {
    show:function({content='', error=false }){
      setModalInfoData(content);
      // setToastError(error);
      setActiveModalInfo(true);
    }
  }  

  const navigationMarkup = (
    <Navigation location="/">
      { props.routeMap.map((section,index) => (
        <Navigation.Section
          key={index}
          fill={props.routeMap.length-1 === index}
          items={section.items}
          title={section.section}
        />
      )) }
      <Navigation.Section 
        items={[{
          url:'#',
          label:'Setting',
          icon:SettingsMinor,
          selected:false,
          disabled:true
        }]}
      />
      <Navigation.Section 
        items={[{
          url:null,
          label:'Doc',
          icon:NoteMajor,
          selected:false,
          disabled:false,
          onClick:()=>{
            // console.log(1111111111)
           window.open('https://alidocs.dingtalk.com/i/nodes/mdvQnONayjBJKmbLEdDLWPY2MeXzp5o0') 
          }
        },
        {
          url:null,
          label:'Version',
          icon:InfoMinor,
          selected:false,
          disabled:false,
          onClick:()=>  {
            // console.log('Version')
            async function getVersion(){
              const data = await customFetch('/index/version', {
                method: 'POST',
                body: {}
              });

              setTimeout(() => {
                setModalInfoData({
                  title:'Version',
                  section: data,
                  loading: false
                })
              }, 1000);

            }

            setModalInfoData({
              title:'Version',
              section: {},
              loading: true
            })

            getVersion();
            toggleActiveModalInfo()
          }
        }
      ]}
      />
    </Navigation>
  )

  const toggleMobileNavigationActive = useCallback(() => {
    setMobileNavigationActive(
      (mobileNavigationActive) => !mobileNavigationActive
    );
    // console.log(mobileNavigationActive);
  }, []);

  const toggleUserMenuActive = useCallback(
    () => setUserMenuActive((userMenuActive) => !userMenuActive),
    [],
  );

  const handleLogoutAction = useCallback(
    () => {
      // console.log('Logout action')
      cookie.remove('token', { path: '/' });
      cookie.remove('userInfo', { path: '/' });
      window.location.reload();
    },[]
  );

  const userMenuActions = [
    {
      title: '',
      items: [
        {
          content: 'Account Info',
          helpText: 'Manage your Account status',
        },
        {content: 'Your profile', icon: ProfileMinor, disabled:true},
        {content: 'Help Center', icon: QuestionMarkInverseMinor, disabled:true},
      ],
    },
    {
      title: '',
      items: [
        {content: 'Log out', icon: LogOutMinor, onAction:handleLogoutAction}
      ],
    },
  ]; 

  const userMenuMarkup = 
  (
    <TopBar.UserMenu
      actions={userMenuActions}
      name={props.userInfo ? props.userInfo.username.split('@')[0] : ''}
      detail=  {props.userInfo.username} 
      initials= {props.userInfo.username.charAt(0).toUpperCase()}
      open={userMenuActive}
      onToggle={toggleUserMenuActive}
    />
  );
 

  const topBarMarkup = (
    <TopBar
      userMenu={userMenuMarkup}
      showNavigationToggle={true}
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );  

  const logo = {
    width: 222,
    topBarSource:
      '/logo-big-2.png',
    contextualSaveBarSource:
      '/logo-big-2.png',
    url: '#',
    accessibilityLabel: 'Acuinsight Management',
  };

  const modalInfo = 
    <Modal
      open={activeModalInfo}
      onClose={toggleActiveModalInfo}
      title={modalInfoData.title}
      loading={modalInfoData.loading}
    >
      {/* <Modal.Section>
        { Object.keys(modalInfoData.section).map( key => 
        <Stack alignment="center" key={key}>
          <Text variant="">{key}</Text>
          <Text variant="headingMd">{modalInfoData.section[key]}</Text>
        </Stack>
        )}
      </Modal.Section> */}

{/* { console.log('modalInfoData.section', typeof modalInfoData.section, modalInfoData.section['$$typeof'], modalInfoData.section['$$typeof'] === 'Symbol(react.element)') } */}

{ 
modalInfoData.section['$$typeof']
? modalInfoData.section 
: Object.keys(modalInfoData.section).map( key => 
    <Modal.Section key={key} >
      <Badge status="info"><Text variant="headingSm">{key}</Text></Badge>
      { "object" === typeof modalInfoData.section[key] 
      ? 
        Object.keys(modalInfoData.section[key]).map( k => 
          <Stack alignment="center" key={k}>
            <Text variant="">{k}</Text>
            <Text variant="headingMd" ><InlineCode>{modalInfoData.section[key][k]}</InlineCode></Text>
          </Stack>
        )
      : 
        <Stack alignment="center">
          <Text variant="">{key}</Text>
          <Text variant="headingMd"> {modalInfoData.section[key]}</Text>
        </Stack>    
      }
    </Modal.Section>  
  )
}



    </Modal>

  return (
    <Frame
      logo={logo}
      topBar={topBarMarkup}
      navigation={navigationMarkup}
      showMobileNavigation={mobileNavigationActive}
      onNavigationDismiss={toggleMobileNavigationActive}
      // globalRibbon={(<div>globalRibbon </div>)}
    >
      {/* <div style={{backgroundImage: 'url(/illustration-of-warehouse-3.webp)',height:'100%',opacity:0.1, backgroundSize:"cover"}}></div> */}
      {props.children}
      {toastMarkup}
      { modalInfo }
    </Frame>
  )
}
