// import "./styles.css"
// import "./index.css"
import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { BrowserRouter } from "react-router-dom";
import { AppProvider } from "@shopify/polaris";
import translations from "@shopify/polaris/locales/en.json";
import cookie from 'react-cookies';
// import "@shopify/polaris/build/esm/styles.css";
// import "./styles.css"
// import "@shopify/polaris-tokens/dist/css/styles.css"

import moment from "moment-timezone"

if ("development" !== process.env.NODE_ENV) {
  console.log = () => { }
}

const pro_domain = 'https://inv.acuinsight.com'  // https://inv.acuinsight.com:9529

const AppGlobal = window.AppGlobal = {func:{},
  Hosts:{
    HOST_URL  : "development" === process.env.NODE_ENV ? 'https://whpicker-dev.jcbdx.net:8887/whapi' : `${pro_domain}/whapi`,

    // HOST_URL_R_Image :  "development" === process.env.NODE_ENV ? 'https://whpicker-dev.jcbdx.net:8887/files' : 'https://whpicker-dev.jcbdx.net:8887/files',
    // Timeline_Files  : "development" === process.env.NODE_ENV ? 'https://whpicker-dev.jcbdx.net:8887/files' : 'https://whpicker-dev.jcbdx.net:8887/files',
    // File_URL_Timelist : "development" === process.env.NODE_ENV ? "https://whpicker-dev.jcbdx.net:8887/files/static/timeline_upload/" : "https://whpicker-dev.jcbdx.net:8887/files/static/timeline_upload/" ,
    // Image_URL_Rnum_Record : "development" === process.env.NODE_ENV ? "https://whpicker-dev.jcbdx.net:8887/files/static/oem_record/" : "https://whpicker-dev.jcbdx.net:8887/files/static/oem_record/" ,
    // Image_URL_Rnum_temp : "development" === process.env.NODE_ENV ? "https://whpicker-dev.jcbdx.net:8887/files/static/oem_upload_test/" : "https://whpicker-dev.jcbdx.net:8887/files/static/oem_upload_test/" ,

    HOST_URL_R_Image :  "development" === process.env.NODE_ENV ? `${pro_domain}/files` : `${pro_domain}/files`,
    Timeline_Files  : "development" === process.env.NODE_ENV ? `${pro_domain}/files` : `${pro_domain}/files`,
    File_URL_Timelist : "development" === process.env.NODE_ENV ? `${pro_domain}/files/static/timeline_upload/` : `${pro_domain}/files/static/timeline_upload/` ,
    Image_URL_Rnum_Record : "development" === process.env.NODE_ENV ? `${pro_domain}/files/static/rack_record_upload/` : `${pro_domain}/files/static/rack_record_upload/` ,
    Image_URL_Rnum_temp : "development" === process.env.NODE_ENV ? `${pro_domain}/files/static/original_imgs_upload/` : `${pro_domain}/files/static/original_imgs_upload/` ,
    Image_URL_Rnum_split: "development" === process.env.NODE_ENV ? `${pro_domain}/files/static/splitImageOutput/` : `${pro_domain}/files/static/splitImageOutput/` ,
    Image_URL_Rnum_SnapitSeverPath : "development" === process.env.NODE_ENV ? `${pro_domain}/files/static/snapitSeverPath/` : `${pro_domain}/files/static/snapitSeverPath/` ,

    Image_URL_Rnum : "development" === process.env.NODE_ENV ? `${pro_domain}/iImages/` : `${pro_domain}/iImages/` ,
    Image_URL_Hnum : "development" === process.env.NODE_ENV ? `${pro_domain}/hImages/` : `${pro_domain}/hImages/` ,
  }
}


window.AppGlobal.func.getNowByZone = function(){
  let _setting_timezone = cookie.load('setting_timezone') ;
  let setting_timezone = _setting_timezone || 'America/Los_Angeles'
  return moment().tz(setting_timezone).format("YYYY-MM-DD HH:mm")
}

/**
 * 获取 blob
 * @param  {String} url 目标文件地址
 * @return {cb} 
 */
function getBlob(url, cb) {
  var xhr = new XMLHttpRequest();
  xhr.open('GET', url, true);
  xhr.responseType = 'blob';
  xhr.onload = function () {
    if (xhr.status === 200) {
      cb(xhr.response);
    }
  };
  xhr.send();
}

/**
* 保存
* @param  {Blob} blob     
* @param  {String} filename 想要保存的文件名称
*/
function saveAs(blob, filename) {
  if (window.navigator.msSaveOrOpenBlob) {
    navigator.msSaveBlob(blob, filename);
  } else {
    var link = document.createElement('a');
    var body = document.querySelector('body');

    link.href = window.URL.createObjectURL(blob);
    link.download = filename;

    // fix Firefox
    link.style.display = 'none';
    body.appendChild(link);

    link.click();
    body.removeChild(link);

    window.URL.revokeObjectURL(link.href);
  };
}

/**
* 下载
* @param  {String} url 目标文件地址
* @param  {String} filename 想要保存的文件名称
*/
window.AppGlobal.func.download = function(url, filename) {
  getBlob(url, function (blob) {
    saveAs(blob, filename);
  });
};


/**
 * 
 * @param {*} 压缩图片  imgurl 
 * @returns 
 */
window.AppGlobal.func.compress = async function ({imgFile, maxWidth=2048, maxHeight=2048, mimeType=null, qualityArgument=0.92 }) {
  return new Promise((resolve,reject)=>{
    // console.log('-------')
    let filename = imgFile.name,
        file_mimeType = mimeType || imgFile.type,
        img = new Image(),
        maxH = maxHeight,
        maxW = maxWidth;

    img.onload = async function () {
      var cvs = document.createElement('canvas'),
          ctx = cvs.getContext('2d');

      if(img.width > img.height){
        if(img.width > maxW) {
          img.height *= maxW / img.width;
          img.width = maxW;
        }
      }else{
        if(img.height > maxH) {
          img.width *= maxH / img.height;
          img.height = maxH;
        }
      }

      cvs.width = img.width;
      cvs.height = img.height;

      ctx.clearRect(0, 0, cvs.width, cvs.height);
      ctx.drawImage(img, 0, 0, img.width, img.height);

      const _file = await canvas2BlobSync({canvas:cvs, mimeType:file_mimeType, qualityArgument})
      _file.name = filename;
      resolve({file:_file})
    };
    img.onerror=(e)=>{
      reject({error:e})
    }
    img.src = window.URL.createObjectURL(imgFile);
  })
}
// 压缩图片 需要的转换方法
async function canvas2BlobSync({canvas=null, mimeType='image/jpeg', qualityArgument=0.92 }){
  return new Promise((resolve,reject)=>{
    try {
      canvas.toBlob((blob)=>{
        resolve(blob)
      }, mimeType, qualityArgument)
    } catch (error) {
      reject({error:error})
    }
  })
}


/**
 * @d 判断图片是否存在  
 * @param {*} imgurl 
 * @returns 
 */
window.AppGlobal.func.checkImg = function(imgurl){
  return new Promise((resolve, reject) => {
    var ImgObj = new Image(); //判断图片是否存在  
    ImgObj.onload=function(){
      resolve(true)
    };  
    ImgObj.onerror=function(){
      resolve(false)
    };
    ImgObj.src = imgurl;
  })
}

// const theme = {
//   logo: {
//     width: 222,
//     topBarSource:
//       "/logo-big-2.png",
//     contextualSaveBarSource:
//       "/logo-big-2.png",
//     url: "#",
//     accessibilityLabel: "Acuinsight Management",
//   },
//   colorScheme: "light",
// };


const token = cookie.load('token');
const userInfo = cookie.load('userinfo');

// console.log('------------ index ------------------ token:%o, userInfo:%o ', token, userInfo)

ReactDOM.render(
  <BrowserRouter>
    <AppProvider i18n={translations}>
      {/* <ThemeProvider theme={theme}> */}
      <App token={token} userInfo={userInfo} />
      {/* </ThemeProvider> */}
    </AppProvider>
  </BrowserRouter>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
