import React, { useState } from "react";
import { Page, Card, FormLayout, TextField, Button, TextContainer, Text, Thumbnail, Stack, Spinner } from "@shopify/polaris";
import cookie from 'react-cookies'
import { customFetch } from './tools/request';
import md5 from 'js-md5';

export default function Login(props) {
  const [userName, setUserName] = useState('');
  const [password, setPassword] = useState('');
  const [loading, setLoading] = useState(false);
console.log('%PUBLIC_URL%')
  async function login(params){
    setLoading(true);
    const data = await customFetch('/management/index/login', {
      method: 'POST',
      body: {
        username:params.userName, 
        // password:params.password, 
        pwdmd5:md5(password)}
    });
    console.log(data)

    if(data.error){
      console.log(data.error)
      setLoading(false);
    }else{
      let inFifteenMinutes = new Date(new Date().getTime() + 24 * 3600 * 1000);//一天
      cookie.save('token', data.token, { path: '/', expires: inFifteenMinutes });
      cookie.save('userinfo', JSON.stringify({ ...data.userInfo, _t: new Date() }), {path: '/', expires: inFifteenMinutes })
      cookie.remove('authfail', { path: '/' });
      if ("function" == typeof props.onLogin) {
        props.onLogin()
      } else {
        window.location.reload()
      }
    }
    
  }

  return (
    <Page fullWidth={false}>
      <div className='login'>
        <Card sectioned>
          <Stack alignment="center">
            <Thumbnail
              // source="https://cdn.shopifycdn.net/s/files/1/0507/2345/5144/files/integrate-express-logo_160x160.png"
              source={`https://${window.location.host}/logo192.png`}
              size="medium"
              alt="Black choker necklace"
            />
            <Text variant="heading2xl" as="p">Acuinsight Management</Text>
          </Stack>
          <br />
          <br />
          <TextContainer>
            <Text variant="heading2xl" as="p">Log in</Text>
            <Text variant="headingLg" as="p">Continue to Manage</Text>
          </TextContainer>
          <br />
          <br />

          { loading ? (
            <Stack distribution="center"><Spinner /></Stack>
          ) : (
          <FormLayout>
            <TextField prefix="Account" labelHidden label="User Name" type="text" placeholder="User Name" value={userName} onChange={setUserName} autoComplete="email"/>
            <TextField
              prefix="Password"
              value={password}
              type="password"
              label="Password"
              labelHidden
              placeholder="Password"
              onChange={setPassword}
            />
            <Button size="large" primary onClick={() => {
              login({ userName, password })
            }}>Log in</Button>
          </FormLayout>
          ) }


        </Card>
      </div>
    </Page>
  )
}